import { inject, Injectable, signal } from '@angular/core';
import { WindowService } from './window.service';
import { AnnouncementCard } from '../customer-administration/create-announcement/announcement';

@Injectable({providedIn: 'root'})
export class AnnouncementLocalStorageService {
  windowService = inject(WindowService);

  readonly IS_ANNOUNCEMENT_EXPANDED = 'is-announcement-expanded';
  readonly ANNOUNCEMENTS_ID_LIST = 'announcements-id-list';

  storage = this.windowService.nativeWindow.localStorage;
  announcementToggleEvent = signal<boolean>(this.isAnnouncementExpandedToggleTrue());

  toggleAnnouncementExpanded(): void {
    this.setAnnouncementExpanded(!(this.isAnnouncementExpandedToggleTrue()));
  }

  initAnnouncementExpandedToggle(data: AnnouncementCard[]): void {
    if (this.getAnnouncementExpandedToggle() === null || this.getAnnouncementExpandedToggle() === '') {
      this.setAnnouncementExpanded(true);
      return;
    }

    const announcementIds: string[] = data.map(card => String(card.body.id));
    if (this.latestIdsDifferFromStoredIds(announcementIds, this.getStoredAnnouncements())) {
      this.storeLoadedAnnouncements(announcementIds);
      this.setAnnouncementExpanded(true);
    }
  }

  removeAnnouncementFromStored(removedId: string) {
    const announcementIds = this.getStoredAnnouncements().filter(announcement => announcement != removedId);
    this.storage.removeItem(this.ANNOUNCEMENTS_ID_LIST);
    this.storeLoadedAnnouncements(announcementIds);
  }

  private setAnnouncementExpanded(toggle: boolean): void {
    this.storage.setItem(this.IS_ANNOUNCEMENT_EXPANDED, '' + toggle);
    this.announcementToggleEvent.set(toggle);
  }

  private isAnnouncementExpandedToggleTrue(): boolean {
    return this.getAnnouncementExpandedToggle() === 'true';
  }

  private getAnnouncementExpandedToggle(): string {
    return this.storage.getItem(this.IS_ANNOUNCEMENT_EXPANDED);
  }

  private storeLoadedAnnouncements(identifiers: string[]): void {
    this.storage.setItem(this.ANNOUNCEMENTS_ID_LIST, identifiers.join(','));
  }

  private getStoredAnnouncements(): string[] {
    const announcementIdList = this.storage.getItem(this.ANNOUNCEMENTS_ID_LIST);
    if (announcementIdList !== null && announcementIdList !== '') {
      return announcementIdList.split(',')
    }
    return [];
  }

  deleteAllAnnouncementVariables() {
    this.storage.removeItem(this.ANNOUNCEMENTS_ID_LIST);
    this.storage.removeItem(this.IS_ANNOUNCEMENT_EXPANDED);
  }

  private latestIdsDifferFromStoredIds(latestIds: string[], storedIds: string[]): boolean {
    return latestIds.some(id => !storedIds.includes(id));
  }

}
